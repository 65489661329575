import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Activity/Views/WalletHistory/Common/Address.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { "data-cy": "wallet-history-table-row" };
const _hoisted_2 = { class: "h-12 font-mono pl-3" };
const _hoisted_3 = {
  class: "text-gray-400 text-xs",
  "data-cy": "wallet-history-time-table-data"
};
const _hoisted_4 = { class: "h-12 text-left" };
const _hoisted_5 = {
  "data-cy": "wallet-history-operation-type-table-data",
  class: "text-white text-xs"
};
const _hoisted_6 = { class: "h-12 text-left cursor-pointer" };
const _hoisted_7 = { class: "flex items-center justify-start" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { class: "ml-3" };
const _hoisted_10 = {
  class: "text-gray-200 text-xs",
  "data-cy": "wallet-history-asset-table-data"
};
const _hoisted_11 = { class: "h-12 text-right font-mono" };
const _hoisted_12 = { class: "text-xs text-gray-500" };
const _hoisted_13 = { class: "h-12 text-left font-mono" };
const _hoisted_14 = { class: "h-12 text-left font-mono" };
const _hoisted_15 = { class: "text-right pr-3" };
const _hoisted_16 = ["href"];
import { BigNumberInWei, formatWalletAddress } from "@injectivelabs/utils";
import { format } from "date-fns";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  BIG_NUMBER_ROUND_HALF_UP_MODE,
  UI_DEFAULT_MIN_DISPLAY_DECIMALS
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Transfer",
  props: {
    transaction: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const { t } = useLang();
    const formattedOrigin = computed(
      () => formatWalletAddress(props.transaction.sender)
    );
    const formattedDestination = computed(
      () => formatWalletAddress(props.transaction.receiver)
    );
    const amount = computed(() => {
      if (!props.transaction.amount) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInWei(props.transaction.amount).toBase(
        props.transaction.token.decimals
      );
    });
    const transferType = computed(() => {
      if (props.transaction.sender.startsWith("0x")) {
        return t("walletHistory.subaccountWithdrawalType");
      }
      return t("walletHistory.subaccountDepositType");
    });
    const time = computed(() => {
      if (!props.transaction.timestamp) {
        return "";
      }
      return format(props.transaction.timestamp, "dd MMM HH:mm:ss");
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_AppNumber = __nuxt_component_1;
      const _component_PartialsActivityViewsWalletHistoryCommonAddress = __nuxt_component_2;
      return _openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(time)), 1)
        ]),
        _createElementVNode("td", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(transferType)), 1)
        ]),
        _createElementVNode("td", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            __props.transaction.token ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_CommonTokenIcon, {
                token: __props.transaction.token,
                md: ""
              }, null, 8, ["token"])
            ])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(__props.transaction.token.symbol), 1)
            ])
          ])
        ]),
        _createElementVNode("td", _hoisted_11, [
          _createVNode(_component_AppNumber, {
            decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS),
            number: _unref(amount),
            "rounding-mode": _unref(BIG_NUMBER_ROUND_HALF_UP_MODE),
            "data-cy": "wallet-history-amount-table-data"
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_12, _toDisplayString(__props.transaction.token.symbol), 1)
            ]),
            _: 1
          }, 8, ["decimals", "number", "rounding-mode"])
        ]),
        _createElementVNode("td", _hoisted_13, [
          _createVNode(_component_PartialsActivityViewsWalletHistoryCommonAddress, {
            xs: "",
            address: __props.transaction.sender,
            "data-cy": "wallet-history-sender-table-data"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(formattedOrigin)), 1)
            ]),
            _: 1
          }, 8, ["address"])
        ]),
        _createElementVNode("td", _hoisted_14, [
          _createVNode(_component_PartialsActivityViewsWalletHistoryCommonAddress, {
            xs: "",
            address: __props.transaction.receiver,
            "data-cy": "wallet-history-receiver-table-data"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(formattedDestination)), 1)
            ]),
            _: 1
          }, 8, ["address"])
        ]),
        _createElementVNode("td", _hoisted_15, [
          _createElementVNode("a", {
            href: __props.transaction.explorerLink,
            "data-cy": "wallet-history-explorer-link",
            target: "_blank",
            class: "text-blue-500 cursor-pointer pr-2 text-xs"
          }, _toDisplayString(_ctx.$t("common.view")), 9, _hoisted_16)
        ])
      ]);
    };
  }
});
